function sidebarCTA() {
	// if (document.querySelector('.page-sidebar') != undefined) {
		console.log('[sidebar.cta] cta arrow');
		// dynamically set sidebar cta arrow size based on cta height
		$('.btn_sidebar_cta, .page-sidebar .btn_cta, .page-sidebar > .button, .sidebar-ctas .button').each(function () {
			var btnHeight = $(this).outerHeight(false),
				arrowHeight = btnHeight / 2;

			if (!$(this).children('.arrow').length) $(this).append('<span class="arrow"></span>');
			$(this).children('.arrow').css({
				'border-left-width': arrowHeight + 'px',
				'border-top-width': arrowHeight + 'px',
				'border-bottom-width': arrowHeight + 'px',
			});
		});
	// } 
}

$(function() {
	sidebarCTA();
});