console.log('Load: [emergency.js]');

// Default emergency URL values (overrides are in config/x.js)
const {
	// University emergency feed (Alertus)
	emergencyFeed = 'https://shared.ontariotechu.ca/global/inc/emergency/emergency-include.php',
	// Alert message bar link
	emergencyLink = 'https://ontariotechu.ca/emergency',
	// Secondary notification feed (CMS)
	notificationFeed = 'https://shared.ontariotechu.ca/global/inc/emergency/notification-feed.xml',
	// Service disruptions feed
	serviceDisruptionsFeed = 'https://news.ontariotechu.ca/topic/service_disruptions.xml'
} = emergencyUrls;

console.log(emergencyFeed);
console.log(notificationFeed);


/**
 * Fetches XML feed
 * @param {string} url URL to fetch
 * @param {function} callback Callback function
 * @param {string} dataType Data type 
 */
function loadXml(url, callback, dataType) {
	$.ajax({
		crossDomain: true,
		cache: true,
		url: url,
		type: 'GET',
		dataType: dataType,
		success: callback,
		error: function (xhr, status, error, url) {
			console.log("Error: Cannot process loadXml() request. Error code = " + xhr.status + " " + xhr.statusText + ". URL: " + url);
		}
	});
}

/**
 * Checks university emergency feed for XML content;
 * if content exists, parses feed for display;
 * if no content exists, checks secondary notification feed for content.
 * @param {data} data XML data 
 */
function parseEmergencyFeed(data) {
	// XML content exists
	if (Object.keys(data).length !== 0) {	
		// Format date
		let date = formatDate(data.pubDate);

		// Emergency page content message
		if ($('#emergency-page-message').length) {
			$('#emergency-page-message').html('<p class="alert-heading">' + data.title + '</p><p class="alert-description">' + data.description + '</p><p class="alert-date">' + date + '</p>');
			$('#emergency-page-message').addClass('emergency-active');
		}

		// Alert message bar (on all pages)
		displayAlertBar("Emergency Alert", data.title, data.description, emergencyLink, date);
	}
	// No XML content exists
	else {
		// On emergency info page: clear message (set to default)
		if ($('#emergency-page-message').length) {
			$('#emergency-page-message').html('<p>There are no emergencies at Ontario Tech University at this time. <br>Local disruptions affecting the university can be found below or on the <a href="https://ontariotechu.ca/disruptions">service disruptions page</a>.</p>');
			$('#emergency-page-message').removeClass('emergencyPresent');
		}

		// On all pages: remove alert bar
		if ($('#alert-message-bar').length) $('#alert-message-bar').remove();

		// On uoit.ca home page: show button on home page
		//if ($('#emergencyButton').length) $('#emergencyButton').show();

		// Check secondary notification feed
		loadXml(notificationFeed, parseNotificationFeed, 'xml')
	}
}

/**
 * Parses secondary notification feed for display
 * (runs when no active Alertus feed content exists)
 * @param {xml} data XML data
 */
function parseNotificationFeed(data) {
	$('channel', data).each(function () {
		if ($(this).children('item').length) {
			let link = $('item', data).children('link').text();
			let heading = $('item', data).children('title').text();
			let subheading = $('item', data).children('description').text();
			let content = $('item', data).children('content')[0].textContent;
			let date = $('item', data).children('pubDate').text();
			let severity = $('item', data).children('severity').text();
			displayAlertBar(heading, subheading, content, link, date, severity);
			// Foundation.reInit('sticky');
		}
		else {
			// Remove alert bar
			if ($('#alert-message-bar').length) $('#alert-message-bar').remove();
		}
	});
}

/**
 * Outputs HTML for alert message bar across top of every page
 * @param {string} heading Alert bar heading (required)
 * @param {string} subheading Alert bar subheading (optional)
 * @param {string} content Main content (optional, but recommended)
 * @param {string} link Alert bar link (optional, but recommended)
 * @param {string} date Date (optional)
 * @param {string} severity For CMS-based notification feed (optioanl)
 */
function displayAlertBar(heading, subheading = '', content = '', link = '', date = '', severity = '') {
	// Display alert bar
	if (!$('#alert-message-bar').length) {
		let htmlOutput = '';
		htmlOutput += `
		<div id="alert-message-bar" class="header-banner ${severity}">`
		if (link != '') htmlOutput += `<a class="hidden-link" href="${link}"><span class="show-for-sr">${heading}</span></a>`;
		htmlOutput += `<div class="header-banner-inner"> 
					<div class="padding">
						<div class="alert-heading">${heading}</div> 
						<div class="alert-message">`;
		if (subheading != '') htmlOutput += `<p class="alert-subheading">${subheading}</p>`;
		if (content != '') htmlOutput += `<div class="alert-content">${content}</div>`;
		if (date != '') htmlOutput += `<p class="alert-date">${date}</p>`;
		htmlOutput += `
						</div> 
					</div> 
				</div>
		</div>`;

		// Display alert bar before <header>
		$('header').before(htmlOutput); 
	}
	// If alert bar is already displayed, just update message
	else {
		$('#alert-message-bar').attr('class', 'header-banner '+severity);
		$('#alert-message-bar .alert-heading').text(heading);
		if (link != '') {
			$('#alert-message-bar a').attr('href', link);
			$('#alert-message-bar a .show-for-sr').text(heading);
		}
		if (subheading != '') $('#alert-message-bar .alert-subheading').text(subheading);
		if (content != '') $('#alert-message-bar .alert-content').text(content);
		if (date != '') $('#alert-message-bar .alert-date').text(date);
	}
}

/**
 * Parses news service disruption feed for display
 * on emergency info page only
 * @param {xml} data XML data
 */
function parseServiceDisruptionsFeed(data) {
	$('channel', data).each(function () {
		// Check if <item> node exists
		if ($(this).children('item').length) {
			// add heading to page
			$('.page-content').append('<div class="row column"><hr><h2>Service disruption news</h2></div>');

			// get news item nodes
			$('item', data).each(function () {
				var title = $(this).children('title').text();
				var image = $(this).children('media\\:content').first().attr('url');
				var imagealt = $(this).children('media\\:content').first().children('media\\:description').text();
				var link = $(this).children('link').text();
				var pubdate = $(this).children('pubDate').text();
				var datetime = formatDate(pubdate);

				// format news item
				var newsItem = `<div class="emergency-page-news-item">
					<a href="${link}" title="${title}"><img src="${image}" alt="${imagealt}" width="100" height="67"></a>
					<p><strong><a href="${link}">${title}</strong></a></p>
					<p class="date">${datetime}</p>
				</div>`;

				// add news item to page
				$('.page-content').append(newsItem);
			});
		}
	});
}

/**
 * Date/time formatting
 * @param {string} date
 */
function formatDate(date) {
	const months = Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December");

	let datetime = new Date(date);
	datetime = months[datetime.getMonth()] + ' ' + datetime.getDate() + ', ' + datetime.getFullYear() + ' ' + addZero(datetime.getHours()) + ':' + addZero(datetime.getMinutes());

	return datetime;
}
// Add leading '0' to times in datetime stamp
function addZero(i) {
	if (i < 10) i = "0" + i;
	return i;
}




(function () {
	loadXml(emergencyFeed, parseEmergencyFeed, 'json');

	// Get service disruptions news feed if on emergency page
	if ($('#emergency-page-message').length) loadXml(serviceDisruptionsFeed, parseServiceDisruptionsFeed, 'xml');
})();

// Check for emergency message every 60 seconds
setInterval(function () {
	loadXml(emergencyFeed, parseEmergencyFeed, 'json')
}, 60000); 

// export { loadXml, parseEmergencyFeed, parseNotificationFeed, displayAlertBar, parseServiceDisruptionsFeed, formatDate, addZero}