console.log('Load: [header/navigation.top.js]');

var searchFocusDelay;

// Close top nav sections on body click
// ------------------------------------
$(document).on('click', function (event) {
	// if clicked item is not one of the following
	if (	 !$(event.target).closest('.toggle_top_nav .top-nav-button').length
			&& !$(event.target).closest('.top-nav-links').length
			&& !$(event.target).closest('.btn-sticky-search button').length
			&& !$(event.target).closest('button.btn-sticky-search').length
			&& !$(event.target).closest('#sticky-search').length
	) {
    // close quick links/sticky search
		$('#sticky-search, .top-nav-links').removeClass('open');
		clearTimeout(searchFocusDelay);
  }
});


// Close top nav sections when main nav is sticky
// ----------------------------------------------
$('header .sticky')
	.on('sticky.zf.stuckto:top', function () {
		// console.log('[navigation.top] sticky.zf is sticky');
		// close top nav links, search
		// $('.sticky-search, .top-nav-links').removeClass('open');
		
		// Move search fields to sticky bar 
		if (document.querySelector('#sticky-search .search-fields-container') == null) {
			var searchFields = document.querySelector('.search-fields-container');
			// remove node from quick links
			searchFields.parentNode.removeChild(searchFields);
			// re-attach in sticky bar
			document.querySelector('#sticky-search .search-bar').prepend(searchFields);
		}
	})
	.on('sticky.zf.unstuckfrom:top', function () {
		// console.log('[navigation.top] sticky.zf at top')

		// close sticky search
		$('#sticky-search').removeClass('open');
		
		// Move search fields to quick links fixed bar
		if (document.querySelector('#quick-links-fixed-bar .search-fields-container') == null) {
			var searchFields = document.querySelector('.search-fields-container');
			// remove node from sticky bar
			searchFields.parentNode.removeChild(searchFields);
			// re-attach in quick links
			document.querySelector('#quick-links-fixed-bar .row').prepend(searchFields);
		}

	});


// Toggle search type
// ------------------
function toggleSearchType() {
	// Scroll top top if sticky search was clicked
	// const lastScrollTop = $(document.documentElement).scrollTop();
	// if (lastScrollTop > 125) $(document.documentElement).scrollTop(lastScrollTop).animate({ scrollTop: 0 }, 600);

	// Show directory fields, set focus to input
	if (document.getElementById('searchType').checked) {
		document.querySelector('#search-type-directory').classList.remove('hide');
		document.querySelector('#search-type-google').classList.add('hide');
		// Delay focus if Quick links button clicked (wait until transition is over)
		if (this.nodeName == 'INPUT') {
			document.querySelector('#search-type-directory input[name=firstname]').focus();		
		} else {
			searchFocusDelay = setTimeout(function () {
				document.querySelector('#search-type-directory input[name=firstname]').focus();
			}, 750);
		}
	}
	// Show Google field, set focus to input
	else {
		document.querySelector('#search-type-google').classList.remove('hide');
		document.querySelector('#search-type-directory').classList.add('hide');
		// Delay focus if clicked element is not toggle input
		if (this.nodeName == 'INPUT') {
			document.querySelector('#search-type-google input').focus();
		} else {
			searchFocusDelay = setTimeout(function () {
				document.querySelector('#search-type-google input').focus();
			}, 750);
		}	
	}
}

(function () {
	const searchType = document.getElementById('searchType');
	const btnQuickLinks = document.querySelector('header .top-nav-button');
	const btnStickySearch = document.querySelector('.btn-sticky-search button');
	
	if (searchType != null && btnQuickLinks != null) {
		searchType.addEventListener('change', toggleSearchType);
		btnQuickLinks.addEventListener('click', toggleSearchType);
		// btnStickySearch.addEventListener('click', toggleSearchType);
	}
})();
