console.log("Load: [foundation.init.js]")

/**
 * Default Foundation settings
 */

// Accordion
// ---------
Foundation.Accordion.defaults.slideSpeed=400;
Foundation.Accordion.defaults.allowAllClosed=true;
Foundation.Accordion.defaults.deepLink=true;
Foundation.Accordion.defaults.deepLinkSmudge = true;

// Upgrade Foundation 5 accordions to Foundation 6
if ($('.accordion .accordion-navigation').length) { 
	$('.accordion-navigation').addClass('accordion-item').attr('data-accordion-item',true);
	$('.accordion-navigation > a').addClass('accordion-title');
	$('.accordion-navigation > .content').addClass('accordion-content').attr('data-tab-content',true);
} 

// Tabs
// ----
Foundation.Tabs.defaults.autoFocus=false;
Foundation.Tabs.defaults.deepLink=true;
Foundation.Tabs.defaults.deepLinkSmudge=true;


// Initialize
// ----------
$(function() {
	$(document).foundation();
	console.log('[foundation.init] initialize foundation');

	// Accordion
	// ---------
	$(".accordion .accordion-title").on("click",function(){
		$(this).blur()
	});

	// Open selected accordion by default
	if (window.location.hash.indexOf('accordion') !== -1) {
		// console.log('[foundation.init] open selected accordion');
		// open accordion
		$('.accordion').foundation('down', $(window.location.hash), false);
		
		// scroll to accordion
		var anchor = window.location.hash;
    //need a hash and a relevant anchor in this tabset
    if(anchor) {
      var $link = $('.accordion').find('[href="'+anchor+'"]');
      if ($link.length) {
        //roll up a little to show the current accordion
        $(window).load(function() {
          var offset = $link.offset(); 
          $('html, body').animate({ scrollTop: (offset.top - 70) }, 300);
        });
       }
    }
	}

	// Form - data abide
	// -----------------
	if ($('form[data-abide]').length) {
		// console.log('[foundation.init] form data-abide');
		// var elem = new Foundation.Abide(element, options);
		$('form[data-abide]')
		  // field element is invalid
		  .on("invalid.zf.abide", function(ev,elem) {
		    console.log("Field id "+ev.target.id+" is invalid");
		  });
	}

	// $(window).resize();
});
