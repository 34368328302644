console.log('Load: [navigation/navigation.main.js]');

/**
 *	Related files:
 *	- navigation/foundation.drilldown1.js
 *	- navigation/hoverintent.js
 *
 *	TABLE OF CONTENTS
 *
 *	1. Open/close main menu
 *			a. Open mega menu
 *			b. Open standard menu
 *			c. Close on body click
 *	2.  Window resize events
 *      a. Show mobile nav by default
 *	3.  Document ready events
 *      a. Initialize drilldown on off-canvas mobile menu
 *      b. Initialize drilldown on sidebar menu
 *      c. Set active menu item (open submenu to current page item)
 */


// 1. Open/close main menu
// -----------------------

// set active class on item hover
$('.menu-main li li, .is-mega-menu li').hover(function () {
	$(this).addClass('active');
}, function () {
	$(this).removeClass('active');
});


function donothing() {}


// 1.a. Open mega menu
// -------------------

function openMegaMenu() {
	// $this = item;
	// close any other open menus
	$('.menu-main .is-drilldown.nav-visible .is-standard-menu').hide();
	// $('.is-mega-menu.nav-visible').fadeOut();
	$('.is-mega-menu.nav-visible, .menu-main .is-drilldown.nav-visible').removeClass('nav-visible').attr('aria-hidden', true);
	$('.menu-main > li > a').removeClass('is-active');

	// find current mega menu 
	var menu = $(this).attr('data-mega-menu'); //console.log(menu); console.log($('#'+menu));  

	// init drilldown
	if (!$('#' + menu).find('[data-drilldown]').data('zfPlugin')) {
		// console.log('init mega drilldown');
		$('#' + menu).find('[data-drilldown]').foundation();
	}

	// open current mega menu 
	$('#' + menu).addClass('nav-visible').attr('aria-hidden', false); // .fadeIn();
	$(this).addClass('is-active');

	// show page overlay
	$('.menu-page-overlay').fadeIn(300);
}

$('.menu-main > li.has-mega-menu > a').hoverIntent({
	over: openMegaMenu,
	out: donothing, //closemega,
	interval: 100
});


// 1.b. Open standard menu
// -----------------------

function openStandardMenu() {
	// close any other open menus
	$('.menu-main .is-drilldown.nav-visible .is-standard-menu').hide();
	$('.is-mega-menu.nav-visible, .menu-main .is-drilldown.nav-visible').removeClass('nav-visible').prop('aria-hidden', true);
	$('.menu-main > li > a').removeClass('is-active');

	// find current menu
	var $menu = $(this).parent('.has-standard-menu'); //.next('.is-drilldown');//.children('.is-standard-menu');

	// init drilldown
	if (!$menu.find('[data-drilldown]').data('zfPlugin')) {
		$menu.find('.is-standard-menu').fadeIn();
		$menu.find('[data-drilldown]').foundation();
	} else {
		$menu.find('.is-standard-menu').fadeIn();
	}
	// open current menu
	$menu.find('.is-drilldown').addClass('nav-visible').prop('aria-hidden', false);
	$(this).addClass('is-active');

	// show page overlay
	$('.menu-page-overlay').fadeIn(300);
}

$('.menu-main > li.has-standard-menu > a').hoverIntent({
	over: openStandardMenu,
	out: donothing,
	interval: 200
});


// 1.c. Close main menu on body click
// ----------------------------------

$(document).on('click', function (event) {
	// if clicked item is not one of the following
	if ((!$(event.target).parents('.menu-main').length && !$(event.target).closest('.is-mega-menu').length) ||
		$(event.target).parents('.close-mega-menu').length) {
		// close main menu
		$('.menu-main .is-drilldown.nav-visible .is-standard-menu').hide();
		// $('.is-mega-menu.nav-visible').fadeOut();
		$('.is-mega-menu.nav-visible, .menu-main .is-drilldown.nav-visible').removeClass('nav-visible').prop('aria-hidden', true); //.slideUp(300);

		$('.menu-main > li > a').removeClass('is-active');
		$('.menu-page-overlay').hide();
	}
});



// 2. Window resize events (reset elements) [windowresize.js]
// -----------------------

// 2.a. Show mobile nav by default (i.e. if nav is too long)
// -------------------------------

function toggleMobileNav(mainNavBreakpoint) {
	if (document.querySelector('.menu-main') != undefined) {
		// console.log('[navigation.main] toggleMobileNav');
		var windowWidth = window.innerWidth;//.outerWidth;
		// console.log('breakpoint ' + mainNavBreakpoint);
		// console.log('windowWidth ' + windowWidth);
		if (document.querySelector('.is-mobile-menu') != null) {
			// show mobile nav
			if (windowWidth < mainNavBreakpoint) {
				document.querySelector('.btn_toggleMobileMenu').classList.remove('hide-for-medium');
				document.querySelector('.is-mobile-menu').classList.remove('hide-for-medium');
				document.querySelector('.menu-main').classList.remove('show-for-medium');
				document.querySelector('.menu-main').classList.add('hide-for-medium');
			}
			// hide mobile nav
			else {
				document.querySelector('.btn_toggleMobileMenu').classList.add('hide-for-medium');
				document.querySelector('.is-mobile-menu').classList.add('hide-for-medium');
				document.querySelector('.menu-main').classList.add('show-for-medium');
				document.querySelector('.menu-main').classList.remove('hide-for-medium');
			}
		}
	}
}


// 3. Document ready events
// ------------------------
var mainNavBreakpoint = 1000;
$(document).ready(function () {

	// Set mobile nav breakpoint 
	if (window.innerWidth > 1000 && document.querySelector('.menu-main') != undefined) {
		var topLevelItems = document.querySelectorAll('.menu-main > li');
		var navWidth = 30;
		for (var i = 0; i < topLevelItems.length; i++) {
			navWidth += topLevelItems[i].offsetWidth;
		}
		mainNavBreakpoint = navWidth;
		console.log('mainNavBreakpoint: ' + mainNavBreakpoint);
		toggleMobileNav(mainNavBreakpoint);
	}

	// 3.a. Initialize drilldown on off-canvas mobile menu
	// ---------------------------------------------------

	$('.off-canvas').on('opened.zf.offcanvas', function () {
		if (!$('.is-mobile-menu').data('zfPlugin')) $('.is-mobile-menu').foundation();
	});

	// 3.b. Initialize drilldown on sidebar menu
	// -----------------------------------------

	if ($('.is-sidebar-menu').length) {
		if (!$('.is-sidebar-menu').data('zfPlugin')) $('.is-sidebar-menu').foundation();
	}


	// 3.c. Set active menu item
	// (open submenu to current page item)
	// -----------------------------------

	// page ID (CMS asset id) 
	if (document.head.querySelector("[name=PageID]") !== null) {
		var pageID = document.head.querySelector("[name=PageID]").content;

		// Main nav
		// $('#nav-container > .menu').find('a[data-asset-id='+pageID+']').addClass('is-active'); 

		// Mega menu
		if ($('.is-mega-menu').length) {
			$('.is-mega-menu').each(function () {
				// set current link
				$(this).find('a[data-asset-id=' + pageID + '][data-reference=false]').parent('li').addClass('active');
				// open parent list items
				$(this).find('a[data-asset-id=' + pageID + '][data-reference=false]').parents('.is-drilldown-submenu-parent').each(function () {
					$('.is-sidebar-menu').foundation('_show', $(this));
				});
			});
		}

		// Sidebar
		if ($('.is-sidebar-menu').length) {
			// set current link 
			$('.is-sidebar-menu').find('a[data-asset-id=' + pageID + '][data-reference=false]').parent('li').addClass('active');
			// open parent lists (starting with topmost level)
			jQuery.fn.reverse = [].reverse;
			$('.is-sidebar-menu').find('a[data-asset-id=' + pageID + '][data-reference=false]').parents('.is-drilldown-submenu-parent').reverse().each(function () {
				$('.is-sidebar-menu').foundation('_show', $(this));
			});

			// remove animation on current list (on initial page load)
			$('.is-sidebar-menu').find('a[data-asset-id=' + pageID + '][data-reference=false]').parents('.is-drilldown-submenu').addClass('no-transition'); //.css({'transition':'none'});
			// re-add animation on current list after menu interaction
			$('.is-sidebar-menu')
				.on("open.zf.drilldown", function (ev, elem) {
					$('.is-sidebar-menu').find('a[data-asset-id=' + pageID + '][data-reference=false]').parents('.is-drilldown-submenu').removeClass('no-transition');
				})
				.on("hide.zf.drilldown", function (ev, elem) {
					// $('.is-sidebar-menu').find('a[data-asset-id='+pageID+']').parent('li').parent('.is-drilldown-submenu').removeClass('no-transition');
				});
		}
	}

});